.toaster-success {
  background-color: #76c22c;
  border-radius: 4px;
}

.toaster-delete {
  background-color: #ff4949;
  border-radius: 4px;
}

.toaster-info {
  background-color: #f7931e;
  border-radius: 4px;
}
